"use client";

import { analytics } from "@components/analytics";
import LoadingSpinner from "@components/loadingSpinner";
import { Dialog, DialogContent } from "@components/popovers/dialog";
import { CheckoutDetails } from "@features/checkout/types";
import Logo from "@features/iTICKET-logo";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import * as Form from "@radix-ui/react-form";
import { useNotificationStore } from "@store/notificationStore";
import { createBrowserClient } from "@supabase/ssr";
import {
  BASE_URL,
  COUNTRY_CODE,
  SUPABASE_ANON_KEY,
  SUPABASE_URL,
} from "@utils/constants";
import { authorizedFetcher, fetcher } from "@utils/fetchers";
import { joinClassNames } from "@utils/helpers";
import { useHydrated, useLatestCookieValue, useWindowSize } from "@utils/hooks";
import axios, { AxiosError } from "axios";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import {
  FormEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import useSWR, { mutate } from "swr";
import { OtpForm } from ".";

interface Props {
  referer: string | null;
}

const SignInModal = ({ referer }: Props) => {
  const basketKey = useLatestCookieValue("ASP.NET_SessionId");
  const router = useRouter();
  const searchParams = useSearchParams();
  const overlay = useRef(null);
  const wrapper = useRef(null);
  const windowSize = useWindowSize();
  const hydrated = useHydrated();
  const supabase = createBrowserClient(SUPABASE_URL!, SUPABASE_ANON_KEY!);
  const setFailure = useNotificationStore((state) => state.setFailure);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [usingPassword, setUsingPassword] = useState<boolean | null>(null);
  const [otpEmail, setOtpEmail] = useState("");
  const pathname = usePathname();
  const { data: checkoutDetails } = useSWR<CheckoutDetails>(
    basketKey ? `basket/${basketKey}/checkout` : null,
    fetcher
  );

  async function handleSyncUser(email: string, password: string) {
    try {
      await axios.post(`${BASE_URL}/legacy/${COUNTRY_CODE}/shop/account/sync`, {
        email,
      });

      const { error, data } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });

      if (error) {
        setLoading(false);
        setFailure(error.message);
        setError(true);
        return "failure";
      }

      analytics.identify(
        data?.user?.app_metadata?.iticket_user_uid
          ? data.user.app_metadata.iticket_user_uid.toLowerCase()
          : email,
        {
          id: data?.user?.app_metadata?.iticket_user_uid?.toLowerCase(),
          email,
        }
      );

      analytics.track("Sign in", {
        iticket_uid: data?.user?.app_metadata?.iticket_user_uid?.toLowerCase(),
        email: email,
        method: "password",
      });

      return "success";
    } catch (error) {
      console.error(error);
      await supabase.auth.signOut();
      analytics.reset();
      setLoading(false);
      setFailure("Invalid login details");
      setError(true);
      return "failure";
    }
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    setLoading(true);
    event.preventDefault();
    const { email, password } = Object.fromEntries(
      new FormData(event.currentTarget)
    );

    if (usingPassword === null) {
      setUsingPassword(true);
      setLoading(false);
      return;
    }

    if (!usingPassword) {
      setLoading(false);
      await axios
        .post(`${BASE_URL}/legacy/${COUNTRY_CODE}/shop/account/sync`, { email })
        .then((data) => data)
        .catch((err) => err);

      const { error } = await supabase.auth.signInWithOtp({
        email: email as string,
        options: {
          shouldCreateUser: false,
        },
      });

      if (error) {
        setFailure("Invalid login details");
        setError(true);
        return;
      }

      const currentSearchParams = new URLSearchParams(
        Array.from(searchParams.entries())
      );
      currentSearchParams.set("redirectTo", "/cart/checkout/payment");

      const search = currentSearchParams.toString();
      const query = search ? `?${search}` : "";

      router.push(`${pathname}${query}`);
      setOtpEmail(email as string);
      return;
    }

    const { error, data } = await supabase.auth.signInWithPassword({
      email: email as string,
      password: password as string,
    });

    if (error) {
      const status = await handleSyncUser(email as string, password as string);

      if (status === "failure") return;
    }

    if (!error && !data.user.app_metadata["iticket_user_uid"]) {
      await supabase.auth.signOut();
      analytics.reset();
      setLoading(false);
      setFailure("Invalid login details");
      setError(true);
      return;
    }

    if (data.user) {
      analytics.identify(
        data?.user?.app_metadata?.iticket_user_uid
          ? data.user.app_metadata.iticket_user_uid.toLowerCase()
          : (email as string),
        {
          id: data?.user?.app_metadata?.iticket_user_uid?.toLowerCase(),
          email: email as string,
        }
      );

      analytics.track("Sign in", {
        iticket_uid: data?.user?.app_metadata?.iticket_user_uid?.toLowerCase(),
        email: email,
        method: "password",
      });
    }

    if (!checkoutDetails?.PostCode && checkoutDetails?.PostCodeRequired) {
      const supabase = createBrowserClient(SUPABASE_URL!, SUPABASE_ANON_KEY!);

      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();

      if (!sessionData.session || sessionError) return;

      const user = await authorizedFetcher([
        "account",
        sessionData.session.access_token,
      ]);

      if (user?.postcode) {
        try {
          await axios.patch(
            `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/basket/${basketKey}/checkout/postcode`,
            {
              postCode: user?.postcode,
            }
          );
          await mutate(`basket/${basketKey}/checkout`);
        } catch (err: AxiosError | any) {
          if (err.response && err.response.status >= 400 && err.status < 500) {
            setFailure(err.response.data.message);
          } else {
            console.error(err);
            setFailure(
              err.response.data.message ?? "Something went wrong",
              true
            );
          }
        }
      }
    }

    router.push("/cart/checkout/payment");
  }

  const { data: checkoutDetail } = useSWR<CheckoutDetails>(
    basketKey ? `basket/${basketKey}/checkout` : null,
    fetcher
  );

  const onDismiss = useCallback(() => {
    if (referer?.includes("form")) {
      router.push("/cart/checkout/form");
      return;
    }

    router.push("/cart");
  }, [router, referer]);

  const onClick: MouseEventHandler = useCallback(
    (e) => {
      if (window.outerWidth && window.outerWidth < 960) {
        onDismiss();
      }

      if (e.target === overlay.current || e.target === wrapper.current) {
        if (onDismiss) onDismiss();
      }
    },
    [overlay, wrapper, onDismiss]
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") onDismiss();
    },
    [onDismiss]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  if (!hydrated) return <></>;

  return windowSize.width && windowSize.width > 960 ? (
    <div
      ref={overlay}
      className="fixed z-50 left-0 right-0 top-0 bottom-0 mx-auto bg-black/60"
      onClick={onClick}
    >
      <motion.div
        ref={wrapper}
        initial="hidden"
        animate="show"
        exit="hidden"
        variants={{
          hidden: {
            opacity: 0,
            translateY: "-50%",
            translateX: "-50%",
            scale: 0.9,
            transition: { duration: 0.125 },
          },
          show: {
            opacity: 1,
            translateY: "-50%",
            translateX: "-50%",
            scale: 1,
          },
        }}
        transition={{ duration: 0.15 }}
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[680px] p-6 "
      >
        <div className="h-full min-h-[calc(100vh-5.5rem-284px)] ">
          <div className="mx-auto max-w-7xl px-4 py-2 sm:px-6 lg:px-8 sm:py-6 lg:py-8">
            <div className="bg-white rounded-2xl">
              <div className="flex flex-col gap-8 pb-6 px-6">
                <div className="flex flex-col items-center justify-center gap-8 p-4 sm:py-6">
                  <div className="flex justify-center items-center rounded-full h-20 w-20 bg-red-500">
                    <Logo size="16" />
                  </div>
                  <div className="flex flex-col gap-4 justify-start w-full">
                    <h1 className="text-2xl text-black font-semibold">
                      Log in to your account
                    </h1>
                    <p className="text-gray-500">
                      Not registered with iTICKET?{" "}
                      <Link
                        href={{
                          pathname: "/account/sign-up",
                          query: "redirectTo=/cart/checkout/payment",
                        }}
                        className="text-indigo-500 font-medium"
                      >
                        Sign up here
                      </Link>
                    </p>
                    <div>
                      {otpEmail ? (
                        <OtpForm
                          email={otpEmail}
                          submitCallback={async () => {
                            if (
                              !checkoutDetails?.PostCode &&
                              checkoutDetails?.PostCodeRequired
                            ) {
                              const supabase = createBrowserClient(
                                SUPABASE_URL!,
                                SUPABASE_ANON_KEY!
                              );

                              const { data: sessionData, error: sessionError } =
                                await supabase.auth.getSession();

                              if (!sessionData.session || sessionError) return;

                              const user = await authorizedFetcher([
                                "account",
                                sessionData.session.access_token,
                              ]);

                              if (user?.postcode) {
                                try {
                                  await axios.patch(
                                    `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/basket/${basketKey}/checkout/postcode`,
                                    {
                                      postCode: user?.postcode,
                                    }
                                  );
                                } catch (err: AxiosError | any) {
                                  if (
                                    err.response &&
                                    err.response.status >= 400 &&
                                    err.status < 500
                                  ) {
                                    setFailure(err.response.data.message);
                                  } else {
                                    console.error(err);
                                    setFailure(
                                      err.response.data.message ??
                                        "Something went wrong",
                                      true
                                    );
                                  }
                                }
                              }
                            }
                          }}
                        />
                      ) : (
                        <Form.Root
                          onSubmit={handleSubmit}
                          onClearServerErrors={() => setError(false)}
                        >
                          <Form.Field
                            name="email"
                            className="flex flex-col gap-1 mb-4"
                            serverInvalid={error}
                          >
                            <div className="flex items-center justify-between h-6">
                              <Form.Label
                                htmlFor="email"
                                className="text-black font-medium"
                              >
                                Email address
                              </Form.Label>
                              <Form.Message
                                match="valueMissing"
                                className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
                              >
                                <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                                <span className="text-sm">Required</span>
                              </Form.Message>
                              <Form.Message
                                match="typeMismatch"
                                className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
                              >
                                <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                                <span className="text-sm">Invalid email</span>
                              </Form.Message>
                              {error && (
                                <div className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800">
                                  <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                                  <span className="text-sm">
                                    Invalid login details
                                  </span>
                                </div>
                              )}
                            </div>
                            <Form.Control
                              className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
                              id="email"
                              name="email"
                              autoComplete="email"
                              data-testid="email"
                              onChange={() => setError(false)}
                              type="email"
                              required
                            />
                          </Form.Field>
                          <AnimatePresence>
                            {!!usingPassword && (
                              <motion.div
                                initial={{
                                  opacity: 0,
                                  height: 0,
                                  marginBottom: 0,
                                }}
                                animate={{
                                  opacity: 1,
                                  height: "auto",
                                  marginBottom: 24,
                                  transition: { opacity: { delay: 0.2 } },
                                }}
                                exit={{
                                  opacity: 0,
                                  height: 0,
                                  marginBottom: 0,
                                  transition: {
                                    height: { delay: 0.2 },
                                    marginBottom: { delay: 0.2 },
                                  },
                                }}
                              >
                                <Form.Field
                                  name="password"
                                  className="mt-4 flex flex-col gap-1"
                                >
                                  <div className="flex items-center justify-between h-6">
                                    <Form.Label
                                      htmlFor="password"
                                      className="text-black font-medium"
                                    >
                                      Password
                                    </Form.Label>
                                    <Form.Message
                                      match="valueMissing"
                                      className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
                                    >
                                      <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                                      <span className="text-sm">Required</span>
                                    </Form.Message>
                                  </div>
                                  <Form.Control
                                    className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
                                    id="password"
                                    name="password"
                                    data-testid="password"
                                    type="password"
                                    required
                                  />
                                </Form.Field>
                                <div className="mt-4">
                                  <Link
                                    href="/account/reset-password"
                                    className="text-indigo-500 font-medium"
                                  >
                                    Forgot your password?
                                  </Link>
                                </div>
                              </motion.div>
                            )}
                          </AnimatePresence>
                          {!usingPassword && (
                            <div className="flex flex-col gap-6">
                              <Form.Submit
                                onMouseDown={() => setUsingPassword(null)}
                                className="w-full p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 disabled:cursor-not-allowed text-white font-semibold transition-colors duration-200 ease-in-out"
                              >
                                Log in with your iTICKET password
                              </Form.Submit>
                              <div className="relative w-full border-t">
                                <span className="absolute -top-0.5 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white text-gray-500 px-2">
                                  or
                                </span>
                              </div>
                              <div className="flex flex-col gap-4">
                                <Form.Submit
                                  onMouseDown={() => setUsingPassword(false)}
                                  disabled={loading}
                                  className="w-full p-2 rounded-md bg-indigo-100 hover:bg-indigo-500 disabled:bg-indigo-200 disabled:cursor-not-allowed text-indigo-800 hover:text-white font-semibold transition-colors duration-200 ease-in-out"
                                >
                                  {loading ? (
                                    <div className="flex justify-center items-center gap-2">
                                      <LoadingSpinner
                                        size="18"
                                        color="#ffffff"
                                      />
                                      <span>Sending one time password...</span>
                                    </div>
                                  ) : (
                                    <span>Request a one time password</span>
                                  )}
                                </Form.Submit>
                                <p className="text-gray-500 text-sm">
                                  If you have a registered iTICKET account, you
                                  can use this option. We will email you a six
                                  digit code that you can use to log in. If you
                                  aren&apos;t registered with iTICKET, you will
                                  not receive an email from us and you need to
                                  sign up using the link above.
                                </p>
                              </div>
                            </div>
                          )}
                          {!!usingPassword && (
                            <div className="flex flex-col gap-4">
                              <Form.Submit
                                data-testid="submit"
                                className="w-full p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 disabled:cursor-not-allowed text-white font-semibold transition-colors duration-200 ease-in-out"
                                disabled={loading}
                              >
                                {loading ? (
                                  <div className="flex justify-center items-center gap-2">
                                    <LoadingSpinner size="18" color="#ffffff" />
                                    <span>Logging in...</span>
                                  </div>
                                ) : (
                                  <span>Log in</span>
                                )}
                              </Form.Submit>
                              <button
                                onClick={() => {
                                  setUsingPassword(null);
                                }}
                                className="w-full p-2 rounded-md bg-indigo-100 hover:bg-indigo-500 disabled:bg-indigo-200 disabled:cursor-not-allowed text-indigo-800 hover:text-white font-semibold transition-colors duration-200 ease-in-out"
                              >
                                Go back
                              </button>
                            </div>
                          )}
                        </Form.Root>
                      )}
                      {checkoutDetail?.GuestBooking ? (
                        <>
                          <div className="relative w-full border-t mt-6">
                            <span className="absolute -top-0.5 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white text-gray-500 px-2">
                              or
                            </span>
                          </div>
                          <Link
                            href={"/cart/checkout/payment"}
                            className="mt-6 flex justify-center w-full p-2 rounded-md bg-indigo-100 hover:bg-indigo-500 disabled:bg-indigo-200 disabled:cursor-not-allowed text-indigo-800 hover:text-white font-semibold transition-colors duration-200 ease-in-out"
                          >
                            Continue as Guest
                          </Link>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  ) : (
    <Dialog open={pathname === "/cart/checkout/sign-in"}>
      <DialogContent
        handleClose={onClick}
        asChild
        className={joinClassNames(
          "bottom-4 !m-2 !rounded-3xl !p-0 !bg-white !w-[90dvw] max-h-[95dvh] !text-indigo-800"
        )}
      >
        <motion.div
          ref={wrapper}
          initial="hidden"
          animate="show"
          exit="hidden"
          variants={{
            hidden: {
              y: 200,
              opacity: 0,
            },
            show: {
              y: 0,
              opacity: 1,
            },
          }}
          transition={{ duration: 0.15 }}
          className="flex flex-col"
        >
          <div className="px-4 pt-4 flex flex-col justify-between items-center flex-1 rounded-t-3xl">
            <div className="relative self-end w-8 h-8 rounded-full bg-gray-400">
              <button
                onClick={onClick}
                className="absolute top-2 left-2 w-4 h-4 text-white !font-bold"
              >
                <XMarkIcon />
              </button>
            </div>
            <div className="flex justify-center items-center rounded-full h-20 w-20 bg-red-500">
              <Logo size="16" />
            </div>
            {checkoutDetail?.GuestBooking ? (
              <>
                <Link
                  href={"/cart/checkout/payment"}
                  className="mt-6 flex justify-center w-full p-2 rounded-md bg-indigo-100 hover:bg-indigo-500 disabled:bg-indigo-200 disabled:cursor-not-allowed text-indigo-800 hover:text-white font-semibold transition-colors duration-200 ease-in-out"
                >
                  Continue as Guest
                </Link>
                <div className="relative w-full border-t mt-6">
                  <span className="absolute -top-0.5 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white text-gray-500 px-2">
                    or
                  </span>
                </div>
              </>
            ) : (
              <div>
                <h1 className="text-2xl text-black font-semibold">
                  Log in to your account
                </h1>
                <p className="text-gray-500">
                  Not registered with iTICKET?{" "}
                  <Link
                    href={"/account/sign-up"}
                    className="text-indigo-500 font-medium"
                  >
                    Sign up here
                  </Link>
                </p>
              </div>
            )}
          </div>
          <div className="max-h-[45dvh] overflow-y-auto grid grid-cols-1 pb-6">
            <div className="flex flex-col items-center justify-center gap-8 px-4 sm:px-6">
              <div className="flex flex-col gap-4 justify-start w-full">
                <div>
                  {otpEmail ? (
                    <OtpForm
                      email={otpEmail}
                      submitCallback={async () => {
                        if (
                          !checkoutDetails?.PostCode &&
                          checkoutDetails?.PostCodeRequired
                        ) {
                          const supabase = createBrowserClient(
                            SUPABASE_URL!,
                            SUPABASE_ANON_KEY!
                          );

                          const { data: sessionData, error: sessionError } =
                            await supabase.auth.getSession();

                          if (!sessionData.session || sessionError) return;

                          const user = await authorizedFetcher([
                            "account",
                            sessionData.session.access_token,
                          ]);

                          if (user?.postcode) {
                            try {
                              await axios.patch(
                                `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/basket/${basketKey}/checkout/postcode`,
                                {
                                  postCode: user?.postcode,
                                }
                              );
                            } catch (err: AxiosError | any) {
                              if (
                                err.response &&
                                err.response.status >= 400 &&
                                err.status < 500
                              ) {
                                setFailure(err.response.data.message);
                              } else {
                                console.error(err);
                                setFailure(
                                  err.response.data.message ??
                                    "Something went wrong",
                                  true
                                );
                              }
                            }
                          }
                        }
                      }}
                    />
                  ) : (
                    <Form.Root
                      onSubmit={handleSubmit}
                      onClearServerErrors={() => setError(false)}
                    >
                      <Form.Field
                        name="email"
                        className="flex flex-col gap-1 mb-4"
                        serverInvalid={error}
                      >
                        <div className="flex items-center justify-between h-6">
                          <Form.Label
                            htmlFor="email"
                            className="text-black font-medium"
                          >
                            Email address
                          </Form.Label>
                          <Form.Message
                            match="valueMissing"
                            className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
                          >
                            <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                            <span className="text-sm">Required</span>
                          </Form.Message>
                          <Form.Message
                            match="typeMismatch"
                            className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
                          >
                            <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                            <span className="text-sm">Invalid email</span>
                          </Form.Message>
                          {error && (
                            <div className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800">
                              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                              <span className="text-sm">
                                Invalid login details
                              </span>
                            </div>
                          )}
                        </div>
                        <Form.Control
                          className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
                          id="email"
                          name="email"
                          autoComplete="email"
                          data-testid="email"
                          type="email"
                          onChange={() => setError(false)}
                          required
                        />
                      </Form.Field>
                      <AnimatePresence>
                        {!!usingPassword && (
                          <motion.div
                            initial={{ opacity: 0, height: 0, marginBottom: 0 }}
                            animate={{
                              opacity: 1,
                              height: "auto",
                              marginBottom: 24,
                              transition: { opacity: { delay: 0.2 } },
                            }}
                            exit={{
                              opacity: 0,
                              height: 0,
                              marginBottom: 0,
                              transition: {
                                height: { delay: 0.2 },
                                marginBottom: { delay: 0.2 },
                              },
                            }}
                          >
                            <Form.Field
                              name="password"
                              className="mt-4 flex flex-col gap-1"
                            >
                              <div className="flex items-center justify-between h-6">
                                <Form.Label
                                  htmlFor="password"
                                  className="text-black font-medium"
                                >
                                  Password
                                </Form.Label>
                                <Form.Message
                                  match="valueMissing"
                                  className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
                                >
                                  <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                                  <span className="text-sm">Required</span>
                                </Form.Message>
                              </div>
                              <Form.Control
                                className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
                                id="password"
                                name="password"
                                data-testid="password"
                                type="password"
                                required
                              />
                            </Form.Field>
                            <div className="mt-4">
                              <Link
                                href="/account/reset-password"
                                className="text-indigo-500 font-medium"
                              >
                                Forgot your password?
                              </Link>
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                      {!usingPassword && (
                        <motion.div
                          layoutId="buttons"
                          layout="position"
                          className="flex flex-col gap-6"
                        >
                          <Form.Submit
                            onMouseDown={() => setUsingPassword(null)}
                            className="w-full p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 disabled:cursor-not-allowed text-white font-semibold transition-colors duration-200 ease-in-out"
                          >
                            Log in with your iTICKET password
                          </Form.Submit>
                          <div className="relative w-full border-t">
                            <span className="absolute -top-0.5 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white text-gray-500 px-2">
                              or
                            </span>
                          </div>
                          <div className="flex flex-col gap-4">
                            <Form.Submit
                              onMouseDown={() => setUsingPassword(false)}
                              disabled={loading}
                              className="w-full p-2 rounded-md bg-indigo-100 hover:bg-indigo-500 disabled:bg-indigo-200 disabled:cursor-not-allowed text-indigo-800 hover:text-white font-semibold transition-colors duration-200 ease-in-out"
                            >
                              {loading ? (
                                <div className="flex justify-center items-center gap-2">
                                  <LoadingSpinner size="18" color="#ffffff" />
                                  <span>Sending one time password...</span>
                                </div>
                              ) : (
                                <span>Request a one time password</span>
                              )}
                            </Form.Submit>
                            <p className="text-gray-500 text-sm">
                              If you have a registered iTICKET account, you can
                              use this option. We will email you a six digit
                              code that you can use to log in. If you
                              aren&apos;t registered with iTICKET, you will not
                              receive an email from us and you need to sign up
                              using the link above.
                            </p>
                          </div>
                        </motion.div>
                      )}
                      {!!usingPassword && (
                        <motion.div
                          layoutId="buttons"
                          layout="position"
                          className="flex flex-col gap-4"
                        >
                          <Form.Submit
                            data-testid="submit"
                            className="w-full p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 disabled:cursor-not-allowed text-white font-semibold transition-colors duration-200 ease-in-out"
                            disabled={loading}
                          >
                            {loading ? (
                              <div className="flex justify-center items-center gap-2">
                                <LoadingSpinner size="18" color="#ffffff" />
                                <span>Logging in...</span>
                              </div>
                            ) : (
                              <span>Log in</span>
                            )}
                          </Form.Submit>
                          <button
                            onClick={() => {
                              setUsingPassword(null);
                            }}
                            className="w-full p-2 rounded-md bg-indigo-100 hover:bg-indigo-500 disabled:bg-indigo-200 disabled:cursor-not-allowed text-indigo-800 hover:text-white font-semibold transition-colors duration-200 ease-in-out"
                          >
                            Go back
                          </button>
                        </motion.div>
                      )}
                    </Form.Root>
                  )}
                </div>
                {checkoutDetail?.GuestBooking ? (
                  <div>
                    <p className="text-gray-500">
                      Not registered with iTICKET?{" "}
                      <Link
                        href={"/account/sign-up"}
                        className="text-indigo-500 font-medium"
                      >
                        Sign up here
                      </Link>
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      </DialogContent>
    </Dialog>
  );
};

export default SignInModal;
