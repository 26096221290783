import * as DialogPrimitive from "@radix-ui/react-dialog";
import { joinClassNames } from "@utils/helpers";
import { motion } from "framer-motion";
import React from "react";

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = ({
  className,
  children,
  ...props
}: DialogPrimitive.DialogPortalProps) => (
  <DialogPrimitive.Portal
    className={joinClassNames(className ?? "")}
    {...props}
  >
    <div className="fixed inset-0 px-6 sm:px-0 z-50 flex items-start justify-center sm:items-center">
      {children}
    </div>
  </DialogPrimitive.Portal>
);
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={joinClassNames(
      "fixed inset-0 z-[60] bg-black/40 ",
      className ?? ""
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

interface ContentWithHandleClose
  extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> {
  handleClose: (e: any) => void;
}

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  ContentWithHandleClose
>(({ className, children, handleClose, ...props }, ref) => (
  <DialogPortal forceMount={props.forceMount}>
    <DialogOverlay asChild forceMount onClick={handleClose}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.95 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.25 }}
      />
    </DialogOverlay>
    <DialogPrimitive.Content
      ref={ref}
      asChild
      className={joinClassNames(
        "fixed z-[60] mx-auto max-w-7xl gap-4 rounded-b-lg border border-gray-200 bg-white p-6 sm:w-full sm:max-w-lg sm:rounded-lg rounded-t-lg md:rounded-lg shadow-modal",
        className ?? ""
      )}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={joinClassNames(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className ?? ""
    )}
    {...props}
  />
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={joinClassNames(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className ?? ""
    )}
    {...props}
  />
);
DialogFooter.displayName = "DialogFooter";

const DialogClose = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Close>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Close>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Close ref={ref} {...props} className={className} />
));

DialogClose.displayName = DialogPrimitive.Close.displayName;

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={joinClassNames(
      "text-lg font-semibold text-black",
      className ?? ""
    )}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={joinClassNames("text-sm text-gray-500", className ?? "")}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
};
