"use client";

import { FormEvent, useRef, useState } from "react";
import * as Form from "@radix-ui/react-form";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { createBrowserClient } from "@supabase/ssr";
import { SUPABASE_ANON_KEY, SUPABASE_URL } from "@utils/constants";
import LoadingSpinner from "@components/loadingSpinner";
import { analytics } from "@components/analytics";
import { useRouter, useSearchParams } from "next/navigation";

interface Props {
  email: string;
  submitCallback?: () => Promise<void>;
  next?: string;
}

export default function CodeInput({ email, submitCallback, next }: Props) {
  const router = useRouter();
  const supabase = createBrowserClient(SUPABASE_URL!, SUPABASE_ANON_KEY!);
  const searchParams = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const input1 = useRef<HTMLInputElement>(null);
  const input2 = useRef<HTMLInputElement>(null);
  const input3 = useRef<HTMLInputElement>(null);
  const input4 = useRef<HTMLInputElement>(null);
  const input5 = useRef<HTMLInputElement>(null);
  const input6 = useRef<HTMLInputElement>(null);
  const otherParams = searchParams
    .toString()
    .split("&")
    .filter(
      (param) => !param.startsWith("redirectTo") && !param.startsWith("country")
    )
    .join("&");

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    const { input1, input2, input3, input4, input5, input6 } =
      Object.fromEntries(new FormData(event.currentTarget));
    const code_value = `${input1}${input2}${input3}${input4}${input5}${input6}`;

    const { data, error } = await supabase.auth.verifyOtp({
      token: code_value,
      type: "email",
      email,
    });

    if (error) {
      setLoading(false);
      setError(true);
      return;
    }

    if (!error && !data.user?.app_metadata["iticket_user_uid"]) {
      await supabase.auth.signOut();
      analytics.reset();
      setLoading(false);
      setError(true);
      return;
    }

    if (data.user) {
      analytics.identify(
        data.user.app_metadata?.iticket_user_uid?.toLowerCase(),
        {
          id: data.user.app_metadata?.iticket_user_uid?.toLowerCase(),
          email: data.user.email,
        }
      );

      analytics.track("Sign in", {
        iticket_uid: data.user.app_metadata?.iticket_user_uid?.toLowerCase(),
        email: email,
        method: "otp",
      });
    }

    if (submitCallback) {
      await submitCallback();
    }

    if (next) {
      router.push(next);
    }

    if (redirectTo) {
      router.replace(`${redirectTo}?${otherParams}`);
    }
    router.refresh();
  }

  return (
    <Form.Root
      onSubmit={handleSubmit}
      onClearServerErrors={() => setError(false)}
      className="flex flex-col gap-8"
    >
      <Form.Field name="code" className="flex flex-col gap-2 items-center">
        <div className="flex items-center gap-2">
          <Form.Control
            ref={input1}
            type="text"
            name="input1"
            inputMode="numeric"
            maxLength={1}
            required
            onFocus={(e) => {
              e.target.select();
            }}
            onChange={(e) => {
              setError(false);
              if (
                e.target.value.length === e.target.maxLength &&
                input2.current
              ) {
                input2.current.focus();
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              const code = e.clipboardData.getData("text");
              if (
                input1.current &&
                input2.current &&
                input3.current &&
                input4.current &&
                input5.current &&
                input6.current
              ) {
                input1.current.value = code[0];
                input2.current.value = code[1];
                input3.current.value = code[2];
                input4.current.value = code[3];
                input5.current.value = code[4];
                input6.current.value = code[5];
                input6.current.focus();
              }
            }}
            className="w-[calc((100%-40px)/6)] max-w-[3rem] aspect-square bg-gray-200 rounded text-xl font-black focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-400 text-center shrink"
          />
          <Form.Control
            ref={input2}
            type="text"
            name="input2"
            inputMode="numeric"
            maxLength={1}
            required
            onFocus={(e) => {
              e.target.select();
            }}
            onChange={(e) => {
              setError(false);
              if (
                e.target.value.length === e.target.maxLength &&
                input3.current
              ) {
                input3.current.focus();
              }
            }}
            onKeyDown={(e) => {
              if (
                e.key === "Backspace" &&
                e.currentTarget.value.length === 0 &&
                input1.current
              ) {
                e.preventDefault();
                input1.current.focus();
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              const code = e.clipboardData.getData("text");
              if (
                input1.current &&
                input2.current &&
                input3.current &&
                input4.current &&
                input5.current &&
                input6.current
              ) {
                input1.current.value = code[0];
                input2.current.value = code[1];
                input3.current.value = code[2];
                input4.current.value = code[3];
                input5.current.value = code[4];
                input6.current.value = code[5];
                input6.current.focus();
              }
            }}
            className="w-[calc((100%-40px)/6)] max-w-[3rem] aspect-square bg-gray-200 rounded text-xl font-black focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-400 text-center shrink"
          />
          <Form.Control
            ref={input3}
            type="text"
            name="input3"
            inputMode="numeric"
            maxLength={1}
            required
            onFocus={(e) => {
              e.target.select();
            }}
            onChange={(e) => {
              setError(false);
              if (
                e.target.value.length === e.target.maxLength &&
                input4.current
              ) {
                input4.current.focus();
              }
            }}
            onKeyDown={(e) => {
              if (
                e.key === "Backspace" &&
                e.currentTarget.value.length === 0 &&
                input2.current
              ) {
                e.preventDefault();
                input2.current.focus();
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              const code = e.clipboardData.getData("text");
              if (
                input1.current &&
                input2.current &&
                input3.current &&
                input4.current &&
                input5.current &&
                input6.current
              ) {
                input1.current.value = code[0];
                input2.current.value = code[1];
                input3.current.value = code[2];
                input4.current.value = code[3];
                input5.current.value = code[4];
                input6.current.value = code[5];
                input6.current.focus();
              }
            }}
            className="w-[calc((100%-40px)/6)] max-w-[3rem] aspect-square bg-gray-200 rounded text-xl font-black focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-400 text-center shrink"
          />
          <Form.Control
            ref={input4}
            type="text"
            name="input4"
            inputMode="numeric"
            maxLength={1}
            required
            onFocus={(e) => {
              e.target.select();
            }}
            onChange={(e) => {
              setError(false);
              if (
                e.target.value.length === e.target.maxLength &&
                input5.current
              ) {
                input5.current.focus();
              }
            }}
            onKeyDown={(e) => {
              if (
                e.key === "Backspace" &&
                e.currentTarget.value.length === 0 &&
                input3.current
              ) {
                e.preventDefault();
                input3.current.focus();
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              const code = e.clipboardData.getData("text");
              if (
                input1.current &&
                input2.current &&
                input3.current &&
                input4.current &&
                input5.current &&
                input6.current
              ) {
                input1.current.value = code[0];
                input2.current.value = code[1];
                input3.current.value = code[2];
                input4.current.value = code[3];
                input5.current.value = code[4];
                input6.current.value = code[5];
                input6.current.focus();
              }
            }}
            className="w-[calc((100%-40px)/6)] max-w-[3rem] aspect-square bg-gray-200 rounded text-xl font-black focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-400 text-center shrink"
          />
          <Form.Control
            ref={input5}
            type="text"
            name="input5"
            inputMode="numeric"
            maxLength={1}
            required
            onFocus={(e) => {
              e.target.select();
            }}
            onChange={(e) => {
              setError(false);
              if (
                e.target.value.length === e.target.maxLength &&
                input6.current
              ) {
                input6.current.focus();
              }
            }}
            onKeyDown={(e) => {
              if (
                e.key === "Backspace" &&
                e.currentTarget.value.length === 0 &&
                input4.current
              ) {
                e.preventDefault();
                input4.current.focus();
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              const code = e.clipboardData.getData("text");
              if (
                input1.current &&
                input2.current &&
                input3.current &&
                input4.current &&
                input5.current &&
                input6.current
              ) {
                input1.current.value = code[0];
                input2.current.value = code[1];
                input3.current.value = code[2];
                input4.current.value = code[3];
                input5.current.value = code[4];
                input6.current.value = code[5];
                input6.current.focus();
              }
            }}
            className="w-[calc((100%-40px)/6)] max-w-[3rem] aspect-square bg-gray-200 rounded text-xl font-black focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-400 text-center shrink"
          />
          <Form.Control
            ref={input6}
            type="text"
            name="input6"
            inputMode="numeric"
            maxLength={1}
            required
            onChange={() => {
              setError(false);
            }}
            onFocus={(e) => {
              e.target.select();
            }}
            onKeyDown={(e) => {
              if (
                e.key === "Backspace" &&
                e.currentTarget.value.length === 0 &&
                input5.current
              ) {
                e.preventDefault();
                input5.current.focus();
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              const code = e.clipboardData.getData("text");
              if (
                input1.current &&
                input2.current &&
                input3.current &&
                input4.current &&
                input5.current &&
                input6.current
              ) {
                input1.current.value = code[0];
                input2.current.value = code[1];
                input3.current.value = code[2];
                input4.current.value = code[3];
                input5.current.value = code[4];
                input6.current.value = code[5];
                input6.current.focus();
              }
            }}
            className="w-[calc((100%-40px)/6)] max-w-[3rem] aspect-square bg-gray-200 rounded text-xl font-black focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-400 text-center shrink"
          />
        </div>
        {error && (
          <div className="flex justify-end w-full px-0.5 sm:px-3">
            <div className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800 w-fit">
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Code is expired or is invalid</span>
            </div>
          </div>
        )}
      </Form.Field>
      <Form.Submit
        disabled={loading}
        className="w-full p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 disabled:cursor-not-allowed text-white font-semibold transition-colors duration-200 ease-in-out"
      >
        {loading ? (
          <div className="flex justify-center items-center gap-2">
            <LoadingSpinner size="18" color="#ffffff" />
            <span>Signing in...</span>
          </div>
        ) : (
          <span>Sign in</span>
        )}
      </Form.Submit>
    </Form.Root>
  );
}
