"use client";

import { CheckIcon } from "@heroicons/react/24/outline";
import CodeInput from "./codeInput";

interface Props {
  email: string;
  submitCallback?: () => Promise<void>;
  next?: string;
}

export default function OtpForm({ email, submitCallback, next }: Props) {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-1 px-4 py-2 bg-emerald-100 ring ring-emerald-800 text-emerald-800 rounded-lg">
        <div className="flex items-center gap-1">
          <CheckIcon className="h-6 w-6" />
          <span className="font-semibold">Code sent!</span>
        </div>
        <p className="text-sm">
          A 6 digit code has been sent to your email, please enter it below.
        </p>
      </div>
      <CodeInput email={email} submitCallback={submitCallback} next={next} />
    </div>
  );
}
