import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/popovers/dialog";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import * as Tooltip from "@radix-ui/react-tooltip";
import { useWindowSize } from "@utils/hooks";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

interface Props {
  old_number: string;
  setPhoneNumber: (phone_number: string | null) => void;
}

export default function PhoneNumberTooltip({
  old_number,
  setPhoneNumber,
}: Props) {
  const windowSize = useWindowSize();
  const [open, setOpen] = useState(false);

  return windowSize.width && windowSize.width > 640 ? (
    <Tooltip.Provider>
      <Tooltip.Root open={open} onOpenChange={setOpen}>
        <Tooltip.Trigger
          onClick={(e) => e.preventDefault()}
          className="flex items-center gap-1 bg-orange-100 text-orange-800 rounded-md px-2 py-1 text-sm cursor-default"
        >
          <ExclamationTriangleIcon className="w-5 h-5" />
          <span>Old number found!</span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="bottom"
            sideOffset={10}
            className="bg-orange-100 w-96 p-4 text-orange-800 rounded-lg animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
          >
            <p className="text-xl font-bold">Old number found!</p>
            <p className="text-sm text-orange-700">
              Would you like to keep this phone number?
            </p>
            <p className="text-lg font-bold w-full text-center">{old_number}</p>
            <div className="flex items-center justify-between">
              <button
                onClick={() => {
                  setPhoneNumber(old_number);
                  setOpen(false);
                }}
                className="flex items-center justify-center py-1.5 px-4 rounded-md bg-orange-500 hover:bg-orange-400 disabled:bg-orange-200 text-white font-semibold transition-colors ease-in-out duration-200"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setPhoneNumber(null);
                  setOpen(false);
                }}
                className="flex items-center justify-center py-1.5 px-4 rounded-md bg-orange-100 hover:bg-orange-200 disabled:bg-orange-200 text-orange-800 font-semibold transition-colors ease-in-out duration-200"
              >
                Discard
              </button>
            </div>
            <Tooltip.Arrow className="fill-orange-100" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  ) : (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="bg-orange-100 text-orange-800 rounded-full p-1 text-sm">
        <ExclamationTriangleIcon className="w-5 h-5" />
      </DialogTrigger>
      <AnimatePresence mode="wait">
        {open && (
          <DialogContent
            asChild
            forceMount
            handleClose={() => setOpen(false)}
            className="bottom-4 !m-2 !rounded-3xl !p-0 !bg-white !max-w-[90dvw] max-h-[95dvh] !text-orange-800 !border-none"
          >
            <motion.div
              initial="hidden"
              animate="show"
              exit="hidden"
              className="flex flex-col"
              transition={{ duration: 0.2 }}
              variants={{
                hidden: {
                  y: 200,
                  opacity: 0,
                },
                show: {
                  y: 0,
                  opacity: 1,
                },
              }}
            >
              <div className="px-6 pt-6 pb-4 flex flex-col justify-between flex-1 bg-orange-500 rounded-t-3xl">
                <div className="relative self-end w-8 h-8 rounded-full bg-white/10">
                  <DialogClose className="absolute top-2 left-2 w-4 h-4 text-white !font-bold">
                    <XMarkIcon />
                  </DialogClose>
                </div>
                <DialogHeader className="!text-left">
                  <DialogTitle className="!text-white text-xl !font-bold">
                    Old number found!
                  </DialogTitle>
                  <DialogDescription className="leading-5 !text-orange-100">
                    Would you like to keep this phone number?
                  </DialogDescription>
                </DialogHeader>
              </div>
              <div className="px-6">
                <p className="text-2xl font-black w-full text-center">
                  {old_number}
                </p>
              </div>
              <div className="flex-1 flex flex-col items-center justify-center gap-3 px-6 pb-6">
                <button
                  onClick={() => {
                    setPhoneNumber(old_number);
                    setOpen(false);
                  }}
                  className="flex items-center justify-center w-full p-2 rounded-md bg-orange-500 text-white font-semibold"
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setPhoneNumber(null);
                    setOpen(false);
                  }}
                  className="flex items-center justify-center w-full p-2 rounded-md bg-orange-100 text-orange-800 font-semibold"
                >
                  Discard
                </button>
              </div>
            </motion.div>
          </DialogContent>
        )}
      </AnimatePresence>
    </Dialog>
  );
}
